<template>
    <div id="page-user-view">

        <div id="user-data" v-if="userInfo">

            <vx-card :title="$t('getKey.info')" class="mb-base">


                <div class="vx-row">


                    <div class="vx-col" id="avatar-col">
                        <div class="img-container mb-4">
                            <img :src="userInfo.avatar" class="rounded w-full"/>
                        </div>
                    </div>

                    <div class="vx-col flex-1" id="account-info-col-1">
                        <table class="mt-8">
                            <tr>
                                <td class="font-semibold">{{$t('getKey.userName')}}</td>
                                <td>{{ userInfo.username }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">{{$t('register.organizationName')}}</td>
                                <td>{{ userInfo.nameOrganization }}</td>
                            </tr>
                            <tr>
                                <td class="font-semibold">Email</td>
                                <td>{{ userInfo.email }}</td>
                            </tr>

                        </table>

                        <vx-card :title="this.$t('getKey.resetPass') + userInfo.username " class=" mt-8">

                            <label class="vs-input--label mt-2 vx-col  w-full">{{$t('getKey.newPass')}}</label>
                                <div class="vx-row w-full">
                                    <vs-input class="mt-2 vx-col w-full statusText mb-3 " type="text" id="myNewPassWord" v-model="isNewPassword"  disabled="true">
                                    </vs-input>
                                    <vx-tooltip   class="mt-5" :text="$t('weekTime.copy') " style="right: 17px; position: absolute;">
                                        <feather-icon v-if="isCheckNewPassWord" :style="{color: '#4c4c4c'}" icon="CopyIcon" svgClasses="h-5 w-5 mr-1 hover:text-primary cursor-pointer" @click="coppyNewPassword"
                                        />
                                        <feather-icon v-if="!isCheckNewPassWord" :style="{color: '#eee'}" icon="CopyIcon" svgClasses="h-5 w-5 mr-1  " />

                                    </vx-tooltip>
                                </div>
                            <vs-button  class="mt-3 vx-col  w-full"  color="warning"
                                        type="border"
                                        icon-pack="feather"
                                        icon="icon-edit" @click="confirmResetPass()">{{$t('getKey.resetPass')}}</vs-button>

                        </vx-card>

                    </div>

                    <div class="vx-col flex-1" id="account-info-col-2">
                        <vx-card :title="$t('getKey.acpetApiAdmin')" class="mb-base">
                            <table>
                                <div class="mb-3">
                                    <div class="vx-row ">
                                        <div class="vx-col"><feather-icon  style="color: sandybrown" icon="AlertTriangleIcon" svgClasses="h-5 w-5 mr-1  cursor-pointer" class="ml-2"
                                        /> </div>
                                        <div class="vx-col textCy mb-3">{{$t('getKey.textAttention')}}</div>
                                    </div>
                                    <p>{{$t('getKey.textTrain1')}}<br>{{$t('getKey.textTrain2')}}<br>{{$t('getKey.textTrain3')}}</p>
                                </div>
<!--                                <div class="mb-5">-->

<!--                                    <p><feather-icon  style="color: sandybrown" icon="AlertTriangleIcon" svgClasses="h-5 w-5 mr-1  cursor-pointer" class="ml-2"-->
<!--                                    />{{$t('getKey.textTrain1')}}<br>{{$t('getKey.textTrain2')}}<br>{{$t('getKey.textTrain3')}}</p>-->
<!--                                </div>-->

<!--                                <label class="vs-input&#45;&#45;label mt-2 vx-col  w-full" >{{$t('getKey.listOrganization')}}</label>-->
<!--                                <v-select class="mt-2 vx-col  w-full mb-8" v-model="selectedOrganizationBranch" :clearable="false" :options="branchOptions"/>-->
                                <label class="vs-input--label mt-2 vx-col  w-full">{{$t('getKey.jurisdiction')}}</label>
                                <div class="w-full mb-8 mt-2" @input="handleRoot">
                                    <vs-checkbox v-model="root" class="ml-4 vx-col">{{$t('getKey.administration')}}
                                    </vs-checkbox>
                                    <vs-checkbox v-model="partner" class="ml-4 vx-col mt-2">{{$t('getKey.organization')}}</vs-checkbox>
                                </div>
                                <label class="vs-input--label mt-2 vx-col  w-full" >{{$t('getKey.secretKey')}}</label>
                                <div class="vx-row w-full">
                                    <vs-input class="mt-2 vx-col w-full statusText mb-3 " type="text" id="mySecretKeys" v-model="isSecretKey"  disabled="true">
                                    </vs-input>
                                    <vx-tooltip   class="mt-5" :text="$t('weekTime.copy') " style="right: 17px; position: absolute;">
                                        <feather-icon v-if="isCheckSecretKey" :style="{color: '#4c4c4c'}" icon="CopyIcon" svgClasses="h-5 w-5 mr-1 hover:text-primary cursor-pointer" @click="coppySecretKey"
                                        />
                                        <feather-icon v-if="!isCheckSecretKey" :style="{color: '#eee'}" icon="CopyIcon" svgClasses="h-5 w-5 mr-1  " />

                                    </vx-tooltip></div>
                                <label class="vs-input--label mt-2 vx-col  w-full" >{{$t('getKey.accessKey')}}</label>
                                <div class="vx-row w-full">
                                    <vs-input class="mb-5 vx-col w-full statusText" v-model="isKeyApi" type="text" id="myAccessKeys" disabled="true"></vs-input>
                                    <vx-tooltip  class="mt-3" :text="$t('weekTime.copy')" style="right: 17px; position: absolute;" >
                                        <feather-icon v-if="isCheckKeyApi"  :style="{color: '#4c4c4c'}" icon="CopyIcon" svgClasses="h-5 w-5 mr-1 hover:text-primary cursor-pointer" @click="coppyAccessKey"
                                        />
                                        <feather-icon v-if="!isCheckKeyApi" :style="{color: '#eee'}" icon="CopyIcon" svgClasses="h-5 w-5 mr-1 "/>

                                    </vx-tooltip>
                                </div>
                                <span>{{$t('getKey.thisKey')}}</span>
                                <vs-button  class="mt-5 vx-col  w-full"  color="primary"
                                            type="border"
                                            :disabled="checkButtonDisabled"
                                            icon-pack="feather"
                                            :icon="iconButton" @click="getKeyApiHandle(statusButton)">{{statusButton}}</vs-button>
                                <vs-button  class="mt-3 vx-col  w-full"  color="danger"
                                            type="border"
                                            :disabled="checkButtonDelete"
                                            icon-pack="feather"
                                            icon="icon-trash-2" @click="deleteApiHandle()">{{$t('getKey.deleteApiKey')}}</vs-button>
                            </table>
                        </vx-card>
                    </div>

                </div>


            </vx-card>

        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select';
export default {
    components: {
        vSelect,
    },
    data() {
        return {
            isNewPassword: null,
            checkButtonDelete: true,
            checkButtonDisabled: false,
            branchOptions: [],
            root: false,
            partner: false,
            userInfo: {
                avatar: require("@/assets/images/portrait/small/inofo.jpg")
            },
            isKeyApi: null,
            isSecretKey: null,
            isCheckNewPassWord: false,
            isCheckKeyApi: false,
            isCheckSecretKey: false,
            statusButton: this.$t('getKey.createButton'),
            iconButton: 'icon-plus',
            keyApiPayLoad: {
                organization: null,
                permissions: []
            },
        }
    },

    watch: {
        '$store.state.userInformation'(val) {
            this.userInfo = Object.assign({}, this.userInfo, val);
        }
    },

    computed: {
        selectedOrganizationBranch: {
            get() {
                this.isKeyApi = '';
                this.isSecretKey = '';
                this.root = false;
                this.partner = false;
                this.checkButtonDisabled = false;
                return {
                    label: this.getLabelString(this.branchOptions.find(x => x.value == this.keyApiPayLoad.organization)),
                    value: this.keyApiPayLoad.organization
                }
            },
            set(obj) {
                this.userInfo.username = obj.label;
                this.userInfo.email = obj.email;
                this.keyApiPayLoad.organization = obj.value;
                this.getKeyApi(obj.value);
                this.organizationId = obj.value;
            }
        },
    },

    methods: {
        checkSecretKey(value){
            if(value == null || value == ''){
                this.isCheckSecretKey = false
            } else {
                this.isCheckSecretKey = true
            }
        },
        checkKeyApi(value){
            if(value == null || value == ''){
                this.isCheckKeyApi = false
            } else {
                this.isCheckKeyApi = true
            }
        },
        coppyNewPassword(){
            let copyText = document.getElementById("myNewPassWord");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText.value);
            return this.$vs.notify({
                text: this.$t('weekTime.textCopyNewPassword'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
            })
        },
        coppySecretKey(){
            let copyText = document.getElementById("mySecretKeys");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText.value);
            return this.$vs.notify({
                text: this.$t('weekTime.textCopySecretKey'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
            })
        },
        coppyAccessKey(){
            let copyText = document.getElementById("myAccessKeys");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            navigator.clipboard.writeText(copyText.value);
            return this.$vs.notify({
                text: this.$t('weekTime.textCopyAccessKey'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
            })
        },
        handleRoot(){
            let rootCheck = this.root;
            let partnerCheck = this.partner;
            if(rootCheck == null && partnerCheck == null) {
                this.checkButtonDisabled = true
            } else {
                this.checkButtonDisabled = false
            }
        },
        getLabelString(object) {
            return (object && object.label) ||  this.$t('menuOrganization.null');
        },
        confirmResetPass() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'warning',
                title: this.$t('warningMenuCustomer.titleReset'),
                text: this.$t('button.titleRessetAc'),
                accept: this.resetPassWordHandle,
                acceptText: this.$t('button.agree'),
                cancelText: this.$t('button.cancel')
            })
        },
        resetPassWordHandle(){
            this.$vs.loading();
            this.$oauth.post('/user/reset-password?username=' +  this.userInfo.username ).then((response) => {
                this.isNewPassword = response.data.newPassword;
                this.isCheckNewPassWord = true;
                this.$vs.notify({
                    title: this.$t('getKey.titleTas'),
                    text: this.$t('getKey.resetPassSuccess'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                })
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        getKeyApi(id){
            this.$vs.loading();
            this.$crm.get('/token/partner/get?organizationId='+ id).then((response) => {
                if(response.data.length == 0){
                    this.statusButton = this.$t('getKey.createButton')
                    this.iconButton = 'icon-plus'
                    this.checkButtonDelete = true;
                    this.checkKeyApi(null);
                    this.checkSecretKey(null);
                } else {
                    this.statusButton = this.$t('getKey.resetButton')
                    this.iconButton = 'icon-edit'
                    this.isKeyApi = response.data.accessKey;
                    this.checkButtonDelete = false;
                    this.checkKeyApi(response.data.accessKey);
                    this.isSecretKey = response.data.secretKey;
                    this.checkSecretKey(response.data.secretKey);
                    if(response.data.permissionToken.indexOf('partner') >= 0)
                    {
                        this.partner = true
                    } else {
                        this.partner = false
                    }
                    if(response.data.permissionToken.indexOf('root') >= 0)
                    {
                        this.root = true
                    } else {
                        this.root = false
                    }
                }
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        getAllBranch(id){
            this.$vs.loading();
            this.$oauth.post('/organization/find-by-id/' + id ).then((response) => {
                this.userInfo.username = response.data.acronym;
                this.userInfo.nameOrganization = response.data.name;
                this.userInfo.email = response.data.email;
                this.keyApiPayLoad.organization = response.data.id;
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        deleteApiHandle(){
            let bid = this.$route.query.id;
            if(bid){
                this.$vs.loading();
                this.$crm.post('/token/partner/admin/delete?organization=' + bid).then(() => {
                    this.isKeyApi = '';
                    this.isSecretKey = '';
                    this.root = false;
                    this.partner = false;
                    this.checkButtonDisabled = false;
                    this.checkButtonDelete = true;
                    this.statusButton = this.$t('getKey.createButton')
                    this.iconButton = 'icon-plus';
                    this.checkSecretKey(null);
                    this.checkKeyApi(null);
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        title: this.$t('getKey.titleTas'),
                        text: this.$t('getKey.deleteSuccess'),
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'success'
                    })
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            } else {
                return this.$vs.notify({
                    text: 'Lỗi',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }
        },
        getKeyApiHandle(name) {
            let checkRoot = this.root;
            let checkPartner = this.partner;
            // let checkSelectBranch = this.selectedOrganizationBranch.value;
            // if(checkSelectBranch == null) {
            //     this.checkButtonDisabled = true
            //     return  this.$vs.notify({
            //         title: this.$t('getKey.err'),
            //         color: 'danger',
            //         text: this.$t('getKey.errValidateBranch'),
            //         iconPack: 'feather',
            //         icon: 'icon-alert-circle',
            //     });
            // }
            if(checkRoot == false && checkPartner == false){
                this.checkButtonDisabled = true
                return  this.$vs.notify({
                    title: this.$t('getKey.err'),
                    color: 'danger',
                    text: this.$t('getKey.errValidate'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                });
            } else {
                let root =  this.root ? '&permissions=root' : '';
                let partner =  this.partner ? '&permissions=partner' : '';
                if (name == this.$t('getKey.resetButton')) {
                    this.$vs.loading();
                    this.$crm.post('/token/partner/admin/renew' + '?organization=' + this.$route.query.id + root + partner).then((response) => {
                        if (response.data.length == 0) {
                            this.statusButton = this.$t('getKey.createButton')
                            this.iconButton = 'icon-plus'
                            this.checkKeyApi(null);
                            this.checkSecretKey(null);
                        } else {
                            this.checkButtonDelete = false;
                            this.statusButton = this.$t('getKey.resetButton');
                            this.iconButton = 'icon-edit';
                            this.isKeyApi = response.data.accessKey;
                            this.isSecretKey = response.data.secretKey;
                            this.checkSecretKey(response.data.secretKey);
                            this.checkKeyApi(response.data.accessKey);
                            this.$vs.notify({
                                title: this.$t('getKey.titleTas'),
                                color: 'success',
                                text: this.$t('getKey.getKeySuccess'),
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                            });
                        }
                        this.$vs.loading.close();
                    }).catch((err) => {
                        this.$vs.loading.close();
                        return this.$vs.notify({
                            text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        });
                    });
                } else {
                    this.$vs.loading();
                    this.$crm.post('/token/partner/admin/create' + '?organization=' + this.$route.query.id + root + partner).then((response) => {
                        if (response.data.length == 0) {
                            this.statusButton = this.$t('getKey.createButton')
                            this.iconButton = 'icon-plus'
                            this.checkSecretKey(null);
                            this.checkKeyApi(null);
                        } else {
                            this.statusButton = this.$t('getKey.resetButton')
                            this.iconButton = 'icon-edit'
                            this.isKeyApi = response.data.accessKey;
                            this.isSecretKey = response.data.secretKey;
                            this.checkButtonDelete = false;
                            this.checkSecretKey(response.data.secretKey);
                            this.checkKeyApi(response.data.accessKey);
                            this.$vs.notify({
                                title: this.$t('getKey.titleTas'),
                                color: 'success',
                                text: this.$t('getKey.createKeySuccess'),
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                            });
                        }
                        this.$vs.loading.close();
                    }).catch((err) => {
                        this.$vs.loading.close();
                        return this.$vs.notify({
                            text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        });
                    });
                }
            }
        }
    },

    created() {
        let idB = this.$route.query.id;
        this.userInfo = Object.assign({}, this.$store.state.userInformation, this.userInfo);
        this.getAllBranch(idB);
        this.getKeyApi(idB);



    },

    mounted() {
        this.setVerticalNavMenuWidth()
    },
}

</script>

<style lang="scss">
#avatar-col {
    width: 10rem;
}

#page-user-view {
    table {
        td {
            vertical-align: top;
            min-width: 140px;
            padding-bottom: .8rem;
            word-break: break-all;
        }

        &:not(.permissions-table) {
            td {
                @media screen and (max-width: 370px) {
                    display: block;
                }
            }
        }
    }
}

.theme-mode {
    width: 200px;
    height: 100px;
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
only screen and (min-width: 636px) and (max-width: 991px) {
    #account-info-col-1 {
        width: calc(100% - 12rem) !important;
    }

}
</style>
<style lang="scss" scoped>
.statusText {
    font-weight: 500;
    color: #2c2c2c;
}
</style>
